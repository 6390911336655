import React from 'react'
import Layout from '@/components/layout'
import Hero from '@/components/hero'
import Intro from '@/components/intro'
import Seo from '@/components/seo'
import Faq from '@/components/faq'
import ScrollToTopButton from '@/components/scrollToTopButton'
import Contact from '@/components/contact'
import Manual from '@/components/manual'

export default function Home() {
  return (
    <Layout>
      <Seo />
      <Hero />
      <Intro />
      <Faq />
      <Manual />
      <Contact />
      <ScrollToTopButton />
    </Layout>
  )
}
