import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const IntroListItem = ({ title, imageClassName, to, className }) => {
  return (
    <li className={className}>
      <Link className="intro__list_lnk" to={to}>
        <span className={imageClassName}></span>
        <span className="intro__list_title">{title}</span>
      </Link>
    </li>
  )
}

IntroListItem.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
}

export default IntroListItem
