import React from 'react'
import IntroListItem from '@/components/introListItem'

const Intro = () => {
  return (
    <div className="intro wrapper">
      <ul className="intro__list">
        <IntroListItem
          className="intro__list_user"
          imageClassName="icon_book_user"
          title="ユーザー向け操作マニュアル"
          to="/categories/ユーザー向け機能説明/"
        />
        <IntroListItem
          className="intro__list_admin"
          imageClassName="icon_book_admin"
          title="管理者向け操作マニュアル"
          to="/categories/管理者向け機能説明/"
        />
      </ul>
    </div>
  )
}

export default Intro
