import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import SeeMoreLink from '@/components/list/seeMoreLink'

const Manual = () => {
  const { userCategories, adminCategories, userArticles, adminArticles } =
    useStaticQuery(graphql`
      query {
        userCategories: allCategoriesJson(
          filter: { category: { eq: "ユーザー向け機能説明" } }
        ) {
          nodes {
            name
            category
            id
          }
        }
        adminCategories: allCategoriesJson(
          filter: { category: { eq: "管理者向け機能説明" } }
        ) {
          nodes {
            name
            category
            id
          }
        }
        userArticles: allMdx(
          filter: { frontmatter: { category: { eq: "ユーザー向け機能説明" } } }
          sort: { order: ASC, fields: fields___order }
        ) {
          nodes {
            id
            frontmatter {
              title
              sub_category
              category
            }
            fields {
              slug
            }
          }
        }
        adminArticles: allMdx(
          filter: { frontmatter: { category: { eq: "管理者向け機能説明" } } }
          sort: { order: ASC, fields: fields___order }
        ) {
          nodes {
            id
            frontmatter {
              title
              sub_category
              category
            }
            fields {
              slug
            }
          }
        }
      }
    `)

  const createManual = (data) => {
    return data.categories.nodes.map((category) => {
      const articles = data.articles.nodes.filter((article) => {
        const { sub_category } = article.frontmatter
        return sub_category === category.name
      })
      if (articles.length === 0) return null
      const html = []
      const limit = 3
      html.push(
        <div key={category.id} className="tp_container_guide__block">
          <h4>
            <span>{category.name}</span>
          </h4>
          <ul className="tp_container_guide_unlst">
            {articles.slice(0, limit).map((article) => {
              const { title } = article.frontmatter
              const { slug } = article.fields
              return (
                <li className="tp_container_guide_lst" key={article.id}>
                  <Link to={slug}>
                    <span>{title}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
          {articles.length > limit ? (
            <SeeMoreLink to={`/categories/${category.name}/`} />
          ) : (
            ''
          )}
        </div>,
      )
      return html
    })
  }
  const userManual = createManual({
    categories: userCategories,
    articles: userArticles,
  })
  const adminManual = createManual({
    categories: adminCategories,
    articles: adminArticles,
  })
  return (
    <div className="tp_wrap_guide wrapper">
      <h2>機能説明</h2>
      <div className="tp_container_guide">
        <div className="tp_bl_guideUser">
          <h3>
            <span>ユーザー向け</span>
          </h3>
          {userManual}
        </div>
        <div className="tp_bl_guideAdmin">
          <h3>
            <span>管理者向け</span>
          </h3>
          {adminManual}
        </div>
      </div>
    </div>
  )
}

export default Manual
