import React from 'react'
import { navigate } from 'gatsby'

export default class Hero extends React.Component {
  state = {
    query: '',
  }

  handleInputChange = ({ target: { value, name } }) => {
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    navigate(`/search/?query=${this.state.query}`)
  }

  render() {
    return (
      <div className="hero_wrapper">
        <div className="hero">
          <h1>ようこそ、Ageluサポートサイトへ</h1>
          <div className="hero__search">
            <form className="form1" onSubmit={this.handleSubmit}>
              <input
                className="sbox"
                type="search"
                name="query"
                value={this.state.query}
                onChange={this.handleInputChange}
                placeholder="キーワードを入力してください"
              />
              <input className="sbtn" type="submit" value="検 索" />
            </form>
          </div>
        </div>
      </div>
    )
  }
}
